import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header_for_all from './Header_for_all';
import Footer_for_all from './Footer_for_all';
import { Menu, X } from 'lucide-react';
import { motion } from 'framer-motion';

const VIDEO_SRC = '/Videos/24.5.mp4';
const buttonClasses = 'bg-secondary text-secondary-foreground p-2 rounded group-hover:bg-black';

const GifVideoContainer = ({ gifSrc, videoSrc }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="relative w-full sm:w-64 h-48 sm:h-64 rounded-lg overflow-hidden p-1 cursor-pointer border-4 border-black"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <video 
          src={videoSrc} 
          autoPlay 
          loop 
          muted 
          className="w-full h-full object-cover"
        />
      ) : (
        <video
          src={gifSrc} 
          autoPlay 
          loop
          muted 
          alt="Looping GIF" 
          className="w-full h-full object-cover"
        />
      )}
    </div>
  );
};

const App = () => {
  return (
    <div 
      className="bg-cover bg-center bg-blue-200 bg-opacity-75 p-8"
      style={{ backgroundImage: "url('/Videos/header5.jpeg')" }}
    >
      <div className="flex flex-col sm:flex-row justify-center items-center sm:items-start h-auto sm:space-x-28 space-y-8 sm:space-y-0 p-4 sm:p-8 bg-opacity-50">
        <GifVideoContainer 
          gifSrc="/Videos/gif1.mp4" 
          videoSrc="/Videos/Video1.mp4" 
        />
        <GifVideoContainer 
          gifSrc="/Videos/gif2.mp4" 
          videoSrc="/Videos/Video2.mp4" 
        />
        <GifVideoContainer 
          gifSrc="/Videos/gif3.mp4" 
          videoSrc="/Videos/Video3.mp4" 
        />
      </div>
      <ComparingMethodsComponent />
    </div>
  );
};

const cardClasses = 'bg-zinc-200 p-6 rounded-lg transition-all duration-300 hover:bg-zinc-800 hover:text-white';
const titleClasses = 'text-xl font-semibold';
const wrapperClasses = 'text-center p-8';
const gridClasses = 'grid grid-cols-1 md:grid-cols-3 gap-4';

const ComparingMethodsComponent = () => {
  return (
    <div className={wrapperClasses}>
      <h1 className="text-2xl font-bold mb-6 text-white">Comparing Traditional Methods vs. Future Drone-Based Approach</h1>
      <div className={gridClasses}>
        <ComparisonCard
          title="Efficiency"
          traditional="Traditional Methods: Time-consuming, requiring extensive manpower and planning."
          drone="Drone-Based Approach: Rapid deployment, reducing time and resources needed for tranquilization operations."
        />
        <ComparisonCard
          title="Safety"
          traditional="Traditional Methods: High risk to both humans and animals, often involving close proximity interactions."
          drone="Drone-Based Approach: Enhanced safety by remotely managing the operation, minimizing direct contact and associated risks."
        />
        <ComparisonCard
          title="Effectiveness"
          traditional="Traditional Methods: Limited accuracy, higher chances of missing the target or causing unnecessary stress to animals."
          drone="Drone-Based Approach: High precision targeting, ensuring effective tranquilization with minimal stress to the animals."
        />
      </div>
    </div>
  );
};

const ComparisonCard = ({ title, traditional, drone }) => {
  return (
    <div className={cardClasses}>
      <h2 className={titleClasses}>{title}</h2>
      <p>{traditional}</p>
      <p>{drone}</p>
    </div>
  );
};

const bgClasses = 'bg-background text-foreground p-6 bg-black';
const textClasses = 'text-white';
const titleClasse = 'text-xl font-bold';
const linkClasses = 'text-primary';

const Footer = () => {
  return (
    <div className={bgClasses}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <FooterItem title="About PSYC" content="Passive Surveillance Yielding Control - Innovating for a safer future." />
        <FooterItem title="Contact Us" content={<ContactContent />} />
        <FooterItem title="Follow Us" content={<FollowContent />} />
      </div>
      <div className="text-muted-foreground text-black text-center mt-4">&copy; 2024 PSYC. All rights reserved.</div>
    </div>
  );
};

const FooterItem = ({ title, content }) => {
  return (
    <div className={textClasses}>
      <h2 className={titleClasse}>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

const ContactContent = () => {
  return (
    <>
      <p>
        Email:{' '}
        <a href="mailto:psycdefence@gmail.com" className={linkClasses}>
          psycdefence@gmail.com
        </a>
      </p>
      <p>
        Phone:{' '}
        <a href="tel:+918861540565" className={linkClasses}>
          +91 88615 40655
        </a>
      </p>
    </>
  );
};

const FollowContent = () => {
  return (
    <>
      <a href="#" className={linkClasses}>
        Facebook
      </a>
      <br />
      <a href="#" className={linkClasses}>
        Twitter
      </a>
      <br />
      <a href="#" className={linkClasses}>
        LinkedIn
      </a>
    </>
  );
};

const StoryBoard = () => {
  return (
    <div>
      <div className="relative overflow-hidden h-screen">
        {/* Background video */}
        <video autoPlay loop muted className="absolute inset-0 w-full h-full object-cover z-0">
          <source src={VIDEO_SRC} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Overlay to darken the video */}
        <div className="absolute inset-0 bg-black opacity-0 z-10"></div>

        {/* Header in the foreground */}
        <Header_for_all />
        
        {/* Content in the foreground */}
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-white text-center z-10 opacity-100 rounded-2xl pb-1"> 
  <h1 className="font-bold text-4xl md:text-6xl lg:text-7xl">PSYC</h1>
  <p className="mt-2 text-xl md:text-4xl lg:text-6xl whitespace-nowrap">Passive Surveillance Yielding Control</p>
</div>

      </div>

      {/* Render the App component (GifVideoContainer) */}
      <App />

      {/* Embed Flipbook with Heading */}
      <div className="text-center my-0 bg-black " style={{ backgroundColor: 'black' }}>
        <h2 className="text-2xl font-bold mb-4 text-white">Conference paper published in IEEE</h2>
        <iframe 
          allowFullScreen="allowfullscreen" 
          scrolling="no" 
          className="fp-iframe" 
          src="https://heyzine.com/flip-book/ffada57089.html" 
          style={{ border: 'none', width: '100%', height: '405px' }}
        ></iframe>
      </div>

      {/* Footer Components */}
      <Footer />
      <Footer_for_all />
    </div>
  );
};

export default StoryBoard;
