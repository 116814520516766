import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Header_for_all from './Header_for_all';
import Footer_for_all from './Footer_for_all';

const HomePage = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [showFullscreenLogo, setShowFullscreenLogo] = useState(true);
  const [showImageOverlayText, setShowImageOverlayText] = useState(false);
  const [showVideoOverlayText, setShowVideoOverlayText] = useState(false);
  const [showThirdOverlayText, setShowThirdOverlayText] = useState(false);
  const [showElephantOverlayText, setShowElephantOverlayText] = useState(false);

  const fullscreenLogoRef = useRef(null);
  const spaceDebrisRef = useRef(null);
  const videoSectionRef = useRef(null);
  const thirdImageRef = useRef(null);
  const elephantImageRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled && window.scrollY > 0) {
        setHasScrolled(true);
        setTimeout(() => {
          setShowFullscreenLogo(false);
        }, 1000);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasScrolled]);

  // Generic observer function with scroll check
  const createObserver = (ref, setter) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasScrolled) {
          setter(true);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) observer.observe(ref.current);
    return () => ref.current && observer.unobserve(ref.current);
  };

  useEffect(() => createObserver(spaceDebrisRef, setShowImageOverlayText), [hasScrolled]);
  useEffect(() => createObserver(videoSectionRef, setShowVideoOverlayText), [hasScrolled]);
  useEffect(() => createObserver(thirdImageRef, setShowThirdOverlayText), [hasScrolled]);
  useEffect(() => createObserver(elephantImageRef, setShowElephantOverlayText), [hasScrolled]);

  return (
    <div className="min-h-auto bg-white relative">
      {/* Fullscreen Logo Animation */}
      {showFullscreenLogo && (
        <div
          ref={fullscreenLogoRef}
          className={`fixed inset-0 z-[9999] flex items-center justify-center pointer-events-none transition-all duration-1000
            ${hasScrolled ? 'scale-[0.3] -translate-y-[40vh] -translate-x-[40vw] opacity-0' : 'opacity-100 scale-100'}
          `}
          style={{ backgroundColor: 'transparent' }}
        >
          <img
            src="Videos/logoNew1.png"
            alt="PSYC Logo"
            className="w-full max-w-[700px] transition-all duration-1000"
          />
        </div>
      )}

      <div className="relative z-10">
        <Header_for_all />

        {/* Section 1: Space Debris */}
        <section
          ref={spaceDebrisRef}
          className="relative w-full min-h-[auto] md:min-h-auto overflow-hidden flex justify-center items-center"
        >
          <img
            src="/Videos/SpaceDebris.jpeg"
            alt="Space Debris"
            className="object-contain w-full h-full"
          />
          {showImageOverlayText && (
            <div className="absolute inset-0 flex flex-col items-start justify-center pl-4 md:pl-10 text-left z-10">
              <p className="text-white text-3xl md:text-5xl font-bold animate-fadeUp mb-4">
                We're working upon AI space
              </p>
              <button className="border border-white text-white px-6 rounded-full hover:bg-white hover:text-black transition">
                Learn More
              </button>
            </div>
          )}
        </section>

        {/* Section 2: Psuedo Seeding Video */}
        <section ref={videoSectionRef} className="relative w-full min-h-auto overflow-hidden">
          <video
            src="/Videos/CloudSeeding.mp4"
            className="w-full h-full object-cover"
            autoPlay
            muted
            loop
            playsInline
          />
          {showVideoOverlayText && (
            <div className="absolute inset-0 flex flex-col items-start justify-center pl-4 md:pl-10 text-left">
              <p className="text-white text-3xl md:text-5xl font-bold animate-fadeUp mb-4">
                Psuedo Seeding
              </p>
              <button className="border border-white text-white px-6 rounded-full hover:bg-white hover:text-black transition">
                Learn More
              </button>
            </div>
          )}
        </section>

        {/* Section 3: Nation Flag */}
        <section
          ref={thirdImageRef}
          className="relative w-full min-h-[auto] md:min-h-auto overflow-hidden flex justify-center items-center"
        >
          <img
            src="/Videos/NationFlag.jpeg"
            alt="Nation Flag"
            className="object-contain w-full h-full"
          />
          {showThirdOverlayText && (
            <div className="absolute inset-0 flex flex-col items-start justify-center pl-4 md:pl-10 text-left z-10">
              <p className="text-white text-3xl md:text-5xl font-bold animate-fadeUp mb-4">
                Built in nation for nation
              </p>
            </div>
          )}
        </section>

        {/* Section 4: Darting Drone */}
        <section
          ref={elephantImageRef}
          className="relative w-full min-h-auto overflow-hidden"
        >
          <div className="relative w-full h-[300px] md:h-auto flex justify-center items-center">
            <img
              src="/Videos/HomeElephant.PNG"
              alt="Darting Drone"
              className="object-fill w-full h-full"
            />
            {showElephantOverlayText && (
              <div className="absolute inset-0 flex items-center justify-start pl-4 text-left z-10">
                <div className="text-black text-2xl md:text-2xl font-bold animate-fadeUp">
                  <p className="mb-0">
                    Our Flagship Product Darting Drone
                  </p>
                  <NavLink
                    to="/StoryBoard"
                    className="border border-black text-black px-16 rounded-full hover:bg-black hover:text-white transition"
                  >
                    Learn More
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </section>

        <Footer_for_all />
      </div>
    </div>
  );
};

export default HomePage;
